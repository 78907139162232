<template>
  <div ref="wrapper" 
    class="typeform">

  </div>
</template>

<script>
  import { createWidget } from '@typeform/embed'
  import '@typeform/embed/build/css/widget.css'
  import { mapGetters } from 'vuex'

  export default {
  computed: {
    ...mapGetters({
      user_meta: 'general/user_meta'
    })
  }, 
  async mounted() {
    const user = await this.$auth.auth0Client.getUser()
    let hidden = {
      name: user.name,
      uuid: user.sub,
      email: user.email
    }
    let inviteToken = this.$localStorage.get('inviteToken')
    if(inviteToken){
      hidden.invite_token = inviteToken
    }
    console.log(hidden, 'hidden')
    createWidget("pApMPruN", {
      container: this.$refs.wrapper,
      hidden: hidden,
      onSubmit: () => {
        if(inviteToken){
           this.$localStorage.remove('inviteToken')
        }
      },
    })
  },
  }
</script>

<style lang="scss" scoped>
.typeform {
  height: calc(100vh - 3rem);
}
</style>